import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'

export default () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(undefined)
    const history = useHistory()

    const login = () => {
        fetch('api/Login?email=' + email + '&password=' + password)
            .then(res => res.json())
            .then(json => {
                if (json == true) {
                    sessionStorage.setItem('loggedIn', true)
                    sessionStorage.setItem('email', email)
                    history.push('/affiliates')
                } else {
                    setError('Invalid login.')
                }
            })
    }

    return (
        <div style={{ width: '300px', margin: 'auto', marginTop: '20px', padding: '20px', backgroundColor: '#efefef', border: '1px solid #ccc', borderRadius: '5px', textAlign: 'center' }}>
            <div style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>ReloFrog Concierge</div>
            <input type="text" placeholder="Email" style={{ width: '80%', height: '24px', display: 'inline-block' }} onChange={(e) => setEmail(e.target.value)} />
            <input type="password" placeholder="Password" style={{ width: '80%', height: '24px', display: 'inline-block' }} onChange={(e) => setPassword(e.target.value)} />
            <br/>
            {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
            <button style={{ marginTop: '20px' }} onClick={login}>Login</button>
        </div>
    )
}