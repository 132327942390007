import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const Affiliates = () => {
    const history = useHistory()
    const [affiliates, setAffiliates] = useState([])
    const [affiliatesFetched, setAffiliatesFetched] = useState(false)
    const [usersFetched, setUsersFetched] = useState(false);
    const [users, setUsers] = useState([]);
    const [filter, setFilter] = useState({
        search: '',
        sortParam: 'modifiedDate',
        sortDescending: true,
        type: 'all'
    })

    const goToAffiliate = (id) => {
        history.push('/affiliate/' + id)
    }

    useEffect(() => {
        const validLogin = Boolean(sessionStorage.getItem('loggedIn'))

        if (!validLogin)
            history.push('/login')
        else {
            if (!affiliatesFetched) {
                fetch('api/Affiliates')
                    .then((res) => {
                        if (res.ok) return res.json()
                        alert('Error fetching affiliates.')
                    })
                    .then((affiliates) => {
                        setAffiliates(affiliates)
                        setAffiliatesFetched(true)
                    })
            }
            if (!usersFetched) {
                fetch('../api/Users/')
                    .then((res) => {
                        if (res.ok) return res.json()
                        alert('Error fetching users.')
                    })
                    .then((users) => {
                        setUsers(users)
                        setUsersFetched(true)
                    })
            }
        }
    })

    const toUser = (id) => {
        history.push('/user/' + id)
    }

    const handleSearchChange = (value) => {
        setFilter({ ...filter, search: value, type: 'all' })
    }

    let filtered = (filter.search && filter.search.length > 0) ?
        users.filter(x => x.firstName.toLowerCase().includes(filter.search)
            || x.lastName.toLowerCase().includes(filter.search)
            || (x.firstName + ' ' + x.lastName).toLowerCase().includes(filter.search)
            || x.email.toLowerCase().includes(filter.search)
            || x.cellPhone.replace(/\D/g, '').includes(filter.search))
        : [];

    const affiliateItems = affiliates.map((aff) => {
        return (
            <div
                key={aff.id}
                onClick={() => goToAffiliate(aff.id)}
                style={{ textAlign: 'center', fontSize: '16px', cursor: 'pointer', border: '1px solid #ccc', borderRadius: '5px', padding: '10px', width: '200px', marginBottom: '20px' }}
            >
                {aff.name}
            </div>
        )
    })

    const filteredUsers = filtered.map((user) => {
        return (
            <div style={{ padding: '5px', borderBottom: '1px solid #ccc', cursor: 'pointer' }} onClick={() => toUser(user.id)}>
                <div>{user.firstName + ' ' + user.lastName}</div>
                <div>{user.email}</div>
                <div>{user.cellPhone}</div>
            </div>
        );
    }).slice(0, 3);

    return (
        <div style={{ width: '100%', maxWidth: '900px', margin: 'auto', marginTop: '20px', backgroundColor: '#efefef', border: '1px solid #ccc', borderRadius: '5px', textAlign: 'center' }}>
            <div style={{ padding: '20px' }}>
                <div style={{ height: '30px', textAlign: 'left', borderRadius: '5px', marginBottom: '10px', position: 'relative' }}>
                    <input className="search" type="text" placeholder="Search..." onChange={(e) => handleSearchChange(e.target.value.toLowerCase())} />
                    {filter.search.length > 0 && (
                        <div style={{ position: 'absolute', top: '35px', left: '0', backgroundColor: 'white', border: '1px solid #ccc', borderRadius: '5px' }}>
                            {filteredUsers}
                        </div>
                    )}
                </div>
                <div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '20px' }}>Select A Corporate Affiliate or Affinity Partner</div>
                <div style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
                    {affiliateItems}
                </div>
            </div>
        </div>
    )
}

export default Affiliates