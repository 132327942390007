import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

export default () => {
    const history = useHistory()

    const [file, setFile] = useState()

    const toDashboard = () => {
        history.push('/dashboard')
    }

    const handleSubmit = () => {
        //const file = document.querySelector('input[type="file"]')

        if (window.FormData !== undefined) {
            const data = new FormData()
            data.append('file', file)//.files[0])

            fetch('api/Users/excel', {
                method: 'POST',
                body: data
            })
                .then((res) => {
                    if (res.ok)
                        history.push('/affiliates')
                })
                .catch(error => alert(error))
        }
    }

    const dropHandle = (ev) => {
        ev.preventDefault()

        if (ev.dataTransfer.items) {
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                if (ev.dataTransfer.items[i].kind === 'file') {
                    setFile(ev.dataTransfer.items[i].getAsFile())
                }
            }
        }
    }

    const dragHandle = (ev) => {
        ev.preventDefault()
    }

    return (
        <div style={{ width: '100%', maxWidth: '900px', margin: 'auto', marginTop: '20px', padding: '20px', backgroundColor: '#efefef', border: '1px solid #ccc', borderRadius: '5px', textAlign: 'center', position: 'relative' }}>
            <div style={{ fontSize: '24px', position: 'absolute', top: '10px', right: '20px', cursor: 'pointer' }} onClick={toDashboard}>X</div>
            <div style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>Upload File</div>
            <div style={{ width: '300px', height: '200px', lineHeight: '200px', border: '1px solid #ccc', margin: 'auto' }} onDragOver={dragHandle} onDrop={dropHandle}>
                {file && <div>{file.name}</div>}
                {!file && <div>Drop your file here!</div>}
            </div>
            <button onClick={handleSubmit} style={{ marginTop: '20px' }}>Upload</button>
        </div>
    )
}

//<input type="file" name="upload" id="upload" />