import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
} from 'react-router-dom'

import Login from './Login'
import Dashboard from './Dashboard'
import Upload from './Upload'
import User from './User'
import AddUser from './AddUser'
import Affiliates from './Affiliates'
import Affiliate from './Affiliate'
import Apartments from './Apartments'

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Switch>
                <Route path='/apartments' component={Apartments} />
                <Route exact path='/user/add' component={AddUser} />
                <Route path='/user/:id' component={User} />
                <Route path='/upload' component={Upload} />
                <Route path='/affiliate/:id' component={Affiliate} />
                <Route path='/affiliates' component={Affiliates} />
                <Route path='/dashboard' component={Dashboard} />
                <Route path='/login' component={Login} />
                <Route exact path='/' component={Login} />
                <Route component={Affiliates} />
            </Switch>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
