import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const User = () => {
    const [user, setUser] = useState()
    const [userFetched, setUserFetched] = useState(false)

    const [notes, setNotes] = useState([])
    const [note, setNote] = useState('')
    const [savingNote, setSavingNote] = useState(false)

    const [callDate, setCallDate] = useState()
    const [startDate, setStartDate] = useState()
    const [moveDate, setMoveDate] = useState()
    const [createdDate, setCreatedDate] = useState()
    const [modifiedDate, setModifiedDate] = useState()

    const { id } = useParams()
    const history = useHistory()

    useEffect(() => {
        const validLogin = Boolean(sessionStorage.getItem('loggedIn'))

        if (!validLogin)
            history.push('/login')
        else {
            if (!userFetched) {
                fetch('../api/Users/' + id)
                    .then((res) => {
                        if (res.ok) return res.json()
                        alert('Error fetching user.')
                    })
                    .then((user) => {
                        setUser(user)
                        setUserFetched(true)

                        setCallDate(user.nextCall ? new Date(user.nextCall) : undefined)
                        setStartDate(user.startDate ? new Date(user.startDate) : undefined)
                        setMoveDate(user.moveDate ? new Date(user.moveDate) : undefined)
                        setCreatedDate(user.creationDate ? new Date(user.creationDate) : undefined)
                        setModifiedDate(user.modifiedDate ? new Date(user.modifiedDate) : undefined)
                        setStatus(user.state)
                        setSubStatus(user.subState)

                        refreshNotes(id)
                    })
            }
        }
    })

    const handleCallDate = (date) => {
        setCallDate(date)
        updateUserProperty('nextCall', date)
    }

    const handleModifiedDate = (date) => {
        setModifiedDate(date)
        updateUserProperty('modifiedDate', date)
    }

    const handleMoveDate = (date) => {
        setMoveDate(date)
        updateUserProperty('moveDate', date)
    }

    const handleStartDate = (date) => {
        setStartDate(date)
        updateUserProperty('startDate', date)
    }

    const handleCreatedDate = (date) => {
        setCreatedDate(date)
        updateUserProperty('createdDate', date)
    }

    const [status, setStatus] = useState('')
    const handleStatus = (status) => {
        setStatus(status)
        updateUserProperty('state', status)
    }

    const [editingListingLink, setEditingListingLink] = useState(false);
    const handleListingLink = (value) => {
        setEditingListingLink(!editingListingLink);
    }

    const handleClickListingLink = () => {
        window.open(user.listingLink, '_blank');
    }

    const [subStatus, setSubStatus] = useState('')
    const handleSubStatus = (subStatus) => {
        setSubStatus(subStatus)
        setUser({ ...user, subState: subStatus })

        const data = {
            userId: id,
            text: 'Status updated to: ' + subStatus
        }

        fetch('../api/Users/notes', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
            .then((res) => {
                if (res.ok) {
                    refreshNotes(id)
                }
            })
            .catch(error => alert(error))
    }

    const updateUserProperty = (propertyName, value) => {
        const result = {}
        result[propertyName] = (propertyName === 'moveDate' || propertyName === 'startDate' || propertyName === 'referenceDate' || propertyName === 'nextCall' || propertyName === 'createdDate') ? (new Date(value)).toJSON() : value
        setUser({
            ...user,
            ...result
        })

        updateAnon(propertyName, value)
    }

    const updateUtility = (utilityName) => {
        const result = {}
        result[utilityName] = !user.utilities[utilityName]
        setUser({
            ...user,
            utilities: { ...user.utilities, ...result }
        })

        updateAnon(utilityName, !user.utilities[utilityName], true)
    }

    const saveNote = () => {
        if (savingNote)
            return

        if (!note || note.length === 0)
            return

        setSavingNote(true)

        const data = {
            userId: id,
            text: note
        }

        fetch('../api/Users/notes', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
            .then((res) => {
                if (res.ok) {
                    document.getElementById('note-box').value = null
                    refreshNotes(id)
                    setSavingNote(false)
                    setNote('')
                }
            })
            .catch(error => alert(error))
    }

    const deleteNote = (id) => {
        if (window.confirm('Delete this note?')) {
            fetch('../api/Users/notes/' + id, {
                method: 'DELETE'
            })
                .then((res) => {
                    if (res.ok)
                        refreshNotes(user.id)
                })
                .catch(error => alert(error))
        }
    }

    const refreshNotes = (id) => {
        fetch('../api/Users/' + id + '/notes')
            .then((res) => {
                if (res.ok) return res.json()
                alert('Error getting notes')
            })
            .then((notes) => {
                setNotes(notes)
            })
    }

    const sendEmail = (id) => {
        fetch('../api/Users/' + id + '/email')
            .then((res) => {
                if (res.ok) console.log(res.body)
                else console.log("Error sending email.")
            })
    }

    const toDashboard = () => {
        history.push('/affiliate/' + user.affiliate.id)
    }

    const updateAnon = (field, value, isUtility = false) => {
        const data = {
            user: user,
            options: user.utilities
        }

        if (isUtility)
            data.user.utilities[field] = value
        else
            data.user[field] = value

        fetch('../api/Users/update', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
            .then((res) => {
                if (res.ok)
                    console.log('Saved')
            })
            .catch(error => console.log(error))
    }

    const submitChanges = () => {
        if (note || note.length > 0)
            saveNote()

        const data = {
            user: user,
            options: user.utilities
        }

        fetch('../api/Users/update', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
            .then((res) => {
                if (res.ok)
                    alert("Changes saved.")
            })
            .catch(error => alert(error))
    }

    const fieldStyles = {
        width: '100%',
        height: '25px',
        lineHeight: '25px',
        marginBottom: '5px',
        border: 'solid 1px #ccc',
        padding: '4px',
        borderRadius: '5px'
    }

    const handleCall = (number) => {
        window.location.href = 'tel:' + number;
    }

    const handleEmail = (email) => {
        window.location = "mailto:" + email;
    }

    /*
    <div style={{ float: 'right', cursor: 'pointer' }}>
        <a href="https://www.dropbox.com/scl/fi/2d6zo0nszn6r41fdtce77/RFC-Settlements.xlsx?dl=0&rlkey=qmototq3gws1dk55n3z6fblkx" target="_blank">Accounting &#8599;</a>
    </div>*/

    //< div style = {{ textAlign: 'right' }}> <button onClick={() => sendEmail(user.id)}>Send Email</button></div >

    return (
        <section>
            { user && (
                <div style={{ width: '100%', maxWidth: '900px', margin: 'auto', marginTop: '20px', backgroundColor: '#efefef', border: '1px solid #ccc', borderRadius: '5px', textAlign: 'center', position: 'relative' }}>
                    <div style={{ padding: '20px' }}>
                        <div style={{ fontSize: '24px', position: 'absolute', top: '10px', right: '20px', cursor: 'pointer' }} onClick={toDashboard}>X</div>
                        <div style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>{user.firstName + ' ' + user.lastName}</div>

                        <div>
                            <div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '10px', textAlign: 'left' }}>Personal Information</div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                <div className="forty-five-width full-width-mobile" style={{ textAlign: 'left' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: '50%', paddingRight: '10px' }}>
                                            <div>First Name:</div>
                                            <input type="text" placeholder="First Name" defaultValue={user.firstName} style={fieldStyles} onBlur={(e) => updateUserProperty('firstName', e.target.value)} />
                                        </div>
                                        <div style={{ width: '50%', paddingLeft: '10px' }}>
                                            <div>Spouse First Name:</div>
                                            <input type="text" placeholder="Spouse First Name" defaultValue={user.spouseFirstName} style={fieldStyles} onBlur={(e) => updateUserProperty('spouseFirstName', e.target.value)} />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: '50%', paddingRight: '10px' }}>
                                            <div>Last Name:</div>
                                            <input type="text" placeholder="Last Name" defaultValue={user.lastName} style={fieldStyles} onBlur={(e) => updateUserProperty('lastName', e.target.value)} />
                                        </div>
                                        <div style={{ width: '50%', paddingLeft: '10px' }}>
                                            <div>Spouse Last Name:</div>
                                            <input type="text" placeholder="Last Name" defaultValue={user.spouseLastName} style={fieldStyles} onBlur={(e) => updateUserProperty('spouseLastName', e.target.value)} />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: '50%', paddingRight: '10px' }}>
                                            <div>Email Address:</div>
                                            <input type="text" placeholder="Email Address" defaultValue={user.email} style={fieldStyles} onBlur={(e) => updateUserProperty('email', e.target.value)} />
                                        </div>
                                        <div style={{ width: '50%', paddingLeft: '10px' }}>
                                            <div>Spouse Email Address:</div>
                                            <input type="text" placeholder="Email Address" defaultValue={user.spouseEmail} style={fieldStyles} onBlur={(e) => updateUserProperty('spouseEmail', e.target.value)} />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: '50%', paddingRight: '10px' }}>
                                            <div style={{ marginTop: '10px' }}>
                                                <div style={{ display: 'inline-block', verticalAlign: 'top', marginRight: '10px' }}>Cell Phone:</div>
                                                <img style={{ cursor: 'pointer' }} onClick={() => handleCall(user.cellPhone)} src="https://cdn2.iconfinder.com/data/icons/font-awesome/1792/phone-512.png" width="25" height="25" />
                                            </div>
                                            <input type="text" placeholder="Cell Phone" defaultValue={user.cellPhone} style={fieldStyles} onBlur={(e) => updateUserProperty('cellPhone', e.target.value)} />
                                        </div>
                                        <div style={{ width: '50%', paddingLeft: '10px' }}>
                                            <div style={{ marginTop: '10px' }}>
                                                <div style={{ display: 'inline-block', verticalAlign: 'top', marginRight: '15px' }}>Spouse Phone:</div>
                                                <img style={{ cursor: 'pointer' }} onClick={() => handleCall(user.homePhone)} src="https://cdn2.iconfinder.com/data/icons/font-awesome/1792/phone-512.png" width="25" height="25" />
                                            </div>
                                            <input type="text" placeholder="Alt Phone" defaultValue={user.homePhone} style={fieldStyles} onBlur={(e) => updateUserProperty('homePhone', e.target.value)} />
                                        </div>
                                    </div>
                                    <div>
                                        <div>Status:</div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <select onChange={(e) => handleStatus(e.target.value)} value={status} style={{ ...fieldStyles, height: '32px', width: '45%' }}>
                                                <option>Select A Status</option>
                                                <option value="pending">Pending</option>
                                                <option value="active">Active</option>
                                                <option value="completed">Completed</option>
                                                <option value="closed">Closed</option>
                                            </select>
                                            <select onChange={(e) => handleSubStatus(e.target.value)} value={subStatus} style={{ ...fieldStyles, height: '32px', width: '45%' }}>
                                                <option>Select A Sub</option>
                                                <option value="Initial Email Sent">Initial Email Sent</option>
                                                <option value="Initial Email 2nd Attempt">Initial Email 2nd Attempt</option>
                                                <option value="Already Moved">Already Moved</option>
                                                <option value="Introductory Call Scheduled">Introductory Call Scheduled</option>
                                                <option value="Home/Apt Searching">Home/Apt Searching</option>
                                                <option value="Research">Research</option>
                                                <option value="Research Completed">Research Completed</option>
                                                <option value="Activations">Activations</option>
                                                <option value="Connection/Set-up Call Scheduled">Connection/Set-up Call Scheduled</option>
                                                <option value="UCR Requested">UCR Requested</option>
                                                <option value="UCR Sent">UCR Sent</option>
                                                <option value="Send Survey">Send Survey</option>
                                                <option value="Survey Sent">Survey Sent</option>
                                                <option value="Awaiting Settlement">Awaiting Settlement</option>
                                                <option value="Completed">Completed</option>
                                                <option value="No Longer Moving">No Longer Moving</option>
                                                <option value="No Response">No Response</option>
                                                <option value="Not Interested">Not Interested</option>
                                                <option value="Archive">Archive</option>
                                                <option value="Misc Email Sent">Misc Email Sent</option>
                                                <option value="Waiting on Call Back">Waiting on Call Back</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="forty-five-width full-width-mobile" style={{ textAlign: 'left' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: '50%', paddingRight: '10px' }}>
                                            <div>Company:</div>
                                            <input type="text" placeholder="Company" defaultValue={(user.affiliate) ? user.affiliate.name : ''} style={fieldStyles} readOnly />
                                        </div>
                                        <div style={{ width: '50%', paddingLeft: '10px' }}>
                                            <div>Employee ID:</div>
                                            <input type="text" placeholder="Employee ID" defaultValue={user.authID} style={fieldStyles} onBlur={(e) => updateUserProperty('authID', e.target.value)} />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: '50%', paddingRight: '10px' }}>
                                            <div>Start Date:</div>
                                            <DatePicker className="datepicker-input" onChange={(date) => handleStartDate(date)} selected={startDate} />
                                        </div>
                                        <div style={{ width: '50%', paddingLeft: '10px' }}>
                                            <div>Move Date:</div>
                                            <DatePicker className="datepicker-input" onChange={(date) => handleMoveDate(date)} selected={moveDate} />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: '50%', paddingRight: '10px' }}>
                                            <div>Created:</div>
                                            <DatePicker className="datepicker-input" onChange={(date) => handleCreatedDate(date)} selected={createdDate} />
                                        </div>
                                        <div style={{ width: '50%', paddingLeft: '10px' }}>
                                            <div>Modified:</div>
                                            <DatePicker className="datepicker-input" onChange={(date) => handleModifiedDate(date)} selected={modifiedDate} />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div style={{ verticalAlign: 'top', display: 'inline-block', marginRight: '10px' }}>Consultant Email:</div>
                                            <img
                                                onClick={() => handleEmail(user.email)}
                                                src="https://cdn4.iconfinder.com/data/icons/basic-user-interface-elements/700/mail-letter-offer-512.png" width="25" height="25" style={{ cursor: 'pointer' }} />
                                        </div>
                                        <input type="text" placeholder="Consultant Email" defaultValue={user.consultantEmail} style={fieldStyles} onBlur={(e) => updateUserProperty('consultantEmail', e.target.value)} />
                                    </div>
                                    <div>
                                        <div>Next Call:</div>
                                        <DatePicker
                                            className="datepicker-input"
                                            showTimeSelect
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            timeFormat="h:mm aa"
                                            onChange={(date) => handleCallDate(date)}
                                            selected={callDate}
                                            dateFormat="MM/dd/yyyy h:mm aa"
                                        />
                                    </div>
                                    <div>
                                        <div style={{marginBottom: '10px'}}>Listing Link: <span style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }} onClick={handleListingLink}>{editingListingLink ? 'Save' : 'Edit' }</span></div>
                                        {editingListingLink && (
                                            <input type="text" placeholder="Listing Link" defaultValue={user.listingLink} style={fieldStyles} onBlur={(e) => updateUserProperty('listingLink', e.target.value)} />
                                        )}
                                        {!editingListingLink && (user.listingLink && <span style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }} onClick={handleClickListingLink}>{user.listingLink}</span> || 'None')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ float: 'right', cursor: 'pointer' }}>
                            <a href={`http://partnerscc.digitalcommercenow.com/?address=${user.newAddress.split(' ').join('+')}&zip=${user.newZip}&agentname=Michael+Bilodeau&agentcode=RFC1&promoid=5002379&locationcode=Relofrog&campaign=Relofrog&secondarycallcode=${user.id}&option5=`} target="_blank">Comcast &#8599;</a>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '10px', textAlign: 'left' }}>Address Information</div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                <div className="forty-five-width full-width-mobile">
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px', textAlign: 'left' }}>
                                        To Address:
                                        <span style={{ marginLeft: '30px' }}>
                                            <select value={user.newAddressType} onChange={(e) => updateUserProperty('newAddressType', e.target.value)} style={{width: '50%'}}>
                                                <option value=""></option>
                                                <option value="Renter">Renter</option>
                                                <option value="Homeowner">Homeowner</option>
                                            </select>
                                        </span>
                                    </div>
                                    <input type="text" placeholder="Street Address" defaultValue={user.newAddress} style={fieldStyles} onBlur={(e) => updateUserProperty('newAddress', e.target.value)} />
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <input type="text" placeholder="City" defaultValue={user.newCity} style={{ ...fieldStyles, width: '50%' }} onBlur={(e) => updateUserProperty('newCity', e.target.value)} />
                                        <input type="text" placeholder="State" defaultValue={user.newState} style={{ ...fieldStyles, width: '15%' }} onBlur={(e) => updateUserProperty('newState', e.target.value)} maxLength="2" />
                                        <input type="text" placeholder="Zip Code" defaultValue={user.newZip} style={{ ...fieldStyles, width: '25%' }} onBlur={(e) => updateUserProperty('newZip', e.target.value)} maxLength="5" />
                                    </div>
                                </div>
                                <div className="forty-five-width full-width-mobile">
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px', textAlign: 'left' }}>
                                        From Address:
                                        <span style={{ marginLeft: '30px' }}>
                                            <select value={user.oldAddressType} onChange={(e) => updateUserProperty('oldAddressType', e.target.value)} style={{ width: '50%' }}>
                                                <option value=""></option>
                                                <option value="Renter">Renter</option>
                                                <option value="Homeowner">Homeowner</option>
                                            </select>
                                        </span>
                                    </div>
                                    <input type="text" placeholder="Street Address" defaultValue={user.oldAddress} style={fieldStyles} onBlur={(e) => updateUserProperty('oldAddress', e.target.value)} />
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <input type="text" placeholder="City" defaultValue={user.oldCity} style={{ ...fieldStyles, width: '50%' }} onBlur={(e) => updateUserProperty('oldCity', e.target.value)} />
                                        <input type="text" placeholder="State" defaultValue={user.oldState} style={{ ...fieldStyles, width: '15%' }} onBlur={(e) => updateUserProperty('oldState', e.target.value)} maxLength="2" />
                                        <input type="text" placeholder="Zip Code" defaultValue={user.oldZip} style={{ ...fieldStyles, width: '25%' }} onBlur={(e) => updateUserProperty('oldZip', e.target.value)} maxLength="5" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '10px', textAlign: 'left' }}>Services</div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                <UtilityBox handleClick={() => updateUtility('changeAddress')} value={user.utilities.changeAddress} name={'Change of Address'} />
                                <UtilityBox handleClick={() => updateUtility('electricity')} value={user.utilities.electricity} name={'Electricity'} />
                                <UtilityBox handleClick={() => updateUtility('gas')} value={user.utilities.gas} name={'Gas'} />
                                <UtilityBox handleClick={() => updateUtility('water')} value={user.utilities.water} name={'Water'} />
                                <UtilityBox handleClick={() => updateUtility('trash')} value={user.utilities.trash} name={'Trash / Recycling'} />
                                <UtilityBox handleClick={() => updateUtility('internet')} value={user.utilities.internet} name={'Broadband / TV'} />
                                <UtilityBox handleClick={() => updateUtility('phone')} value={user.utilities.phone} name={'Phone / Mobile'} />
                                <UtilityBox handleClick={() => updateUtility('warranty')} value={user.utilities.warranty} name={'Home Warranty'} />
                                <UtilityBox handleClick={() => updateUtility('security')} value={user.utilities.security} name={'Home Security'} />
                                <UtilityBox handleClick={() => updateUtility('insurance')} value={user.utilities.insurance} name={'Insurance'} />
                                <UtilityBox handleClick={() => updateUtility('dmv')} value={user.utilities.dmv} name={'DMV'} />
                                <UtilityBox handleClick={() => updateUtility('moving')} value={user.utilities.moving} name={'Moving'} />
                                <UtilityBox handleClick={() => updateUtility('supplies')} value={user.utilities.supplies} name={'Moving Supplies'} />
                                <UtilityBox handleClick={() => updateUtility('housing')} value={user.utilities.housing} name={'Housing'} />
                            </div>
                        </div>
                        <div style={{ marginTop: '20px', textAlign: 'left' }}>
                            <div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '10px', textAlign: 'left' }}>Notes</div>

                            <textarea id="note-box" style={{ width: '100%', height: '100px' }} onChange={(e) => setNote(e.target.value)}></textarea>
                            <button className="clickable-button" onClick={saveNote} disabled={savingNote}>
                                {savingNote ? 'Saving Note...' : 'Save Note'}
                            </button>

                            {notes && notes.length > 0 && (
                                <div style={{ marginTop: '20px', textAlign: 'left', fontSize: '12px', backgroundColor: 'white', border: '1px solid #ccc', borderRadius: '5px', padding: '10px' }}>
                                    {notes.map((note) => (
                                        <div key={note.id} style={{ marginBottom: '10px', display: 'flex', fontWeight: (note.text.includes('Status')) ? 'bold' : 'normal' }}>
                                            <div style={{ width: '20%' }}><i onClick={() => deleteNote(note.id)} className="fas fa-trash-alt" style={{ cursor: 'pointer', marginLeft: '10px' }}></i> {(new Date(note.timeStamp)).toLocaleString()}:</div>
                                            <div style={{ width: '80%', paddingLeft: '10px', wordWrap: 'break-word' }}>{note.text}</div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <button className="clickable-button" onClick={() => submitChanges()}>Save Changes</button>
                            <button className="clickable-button" style={{ marginLeft: '10px' }} onClick={() => toDashboard()}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </section>
    )
}

const UtilityBox = ({ handleClick, value, name }) => {
    return (
        <div className="util-box" onClick={handleClick}>
            <input
                type="checkbox"
                checked={value}
                onChange={(e) => e.preventDefault()}
                style={{ marginTop: '5px' }}
            />
            <div style={{ marginLeft: '10px' }}>{name}</div>
        </div>
    )
}

export default User