import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const AddUser = () => {
    const [user, setUser] = useState({
        authID: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        cellPhone: '',
        homePhone: '',
        startDate: null,
        moveDate: null,
        nextCall: null,
        newAddress: '',
        newCity: '',
        newState: '',
        newZip: '',
        oldAddress: '',
        oldCity: '',
        oldState: '',
        oldZip: '',
        comments: '',
        status: 'pending',
        consultantEmail: '',
        utilities: {
            changeAddress: false,
            internet: false,
            phone: false,
            warranty: false,
            gas: false,
            water: false,
            electricity: false,
            security: false,
            moving: false,
            supplies: false,
            insurance: false,
            dmv: false,
            trash: false
        }
    })

    const [callDate, setCallDate] = useState()
    const [startDate, setStartDate] = useState()
    const [moveDate, setMoveDate] = useState()

    const [affiliates, setAffiliates] = useState([])
    const [affiliatesLoaded, setAffiliatesLoaded] = useState(false)
    const [chosenAffiliate, setChosenAffiliate] = useState()

    const history = useHistory()

    useEffect(() => {
        const validLogin = Boolean(sessionStorage.getItem('loggedIn'))

        if (!validLogin)
            history.push('/login')
        else {
            if (!affiliatesLoaded) {
                setAffiliatesLoaded(true)
                fetch('../api/Affiliates')
                    .then((res) => res.json())
                    .then((affiliates) => setAffiliates(affiliates))
            }
        }
    })

    const handleCallDate = (date) => {
        setCallDate(date)
        setUser({ ...user, nextCall: date.toJSON() })
    }

    const handleMoveDate = (date) => {
        setMoveDate(date)
        setUser({ ...user, moveDate: date.toJSON() })
    }

    const handleStartDate = (date) => {
        setStartDate(date)
        setUser({ ...user, startDate: date.toJSON() })
    }

    const updateUserProperty = (propertyName, value) => {
        const result = {}
        result[propertyName] = (propertyName === 'moveDate' || propertyName === 'startDate' || propertyName === 'referenceDate' || propertyName === 'nextCall') ? (new Date(value)).toJSON() : value
        setUser({
            ...user,
            ...result
        })
    }

    const updateUtility = (utilityName) => {
        const result = {}
        result[utilityName] = !user.utilities[utilityName]
        setUser({
            ...user,
            utilities: { ...user.utilities, ...result }
        })
    }

    const toDashboard = () => {
        history.push('/affiliate/' + chosenAffiliate)
    }

    const submitChanges = () => {
        const data = {
            user: user,
            options: user.utilities,
            affId: Number(chosenAffiliate)
        }

        fetch('../api/Users', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        })
            .then((res) => {
                if (res.ok) {
                    alert("User has been added.")
                    toDashboard()
                }
            })
            .catch(error => alert(error))
    }

    const fieldStyles = {
        width: '100%',
        height: '25px',
        lineHeight: '25px',
        marginBottom: '5px',
        border: 'solid 1px #ccc',
        padding: '4px',
        borderRadius: '5px'
    }

    //< div style = {{ textAlign: 'right' }}> <button onClick={() => sendEmail(user.id)}>Send Email</button></div >

    return (
        <section>
            { user && (
                <div style={{ width: '100%', maxWidth: '900px', margin: 'auto', marginTop: '20px', backgroundColor: '#efefef', border: '1px solid #ccc', borderRadius: '5px', textAlign: 'center', position: 'relative' }}>
                    <div style={{ padding: '20px' }}>
                        <div style={{ fontSize: '24px', position: 'absolute', top: '10px', right: '20px', cursor: 'pointer' }} onClick={toDashboard}>X</div>
                        <div style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>Add New User</div>

                        <div>
                            <div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '10px', textAlign: 'left' }}>Personal Information</div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                <div className="forty-five-width full-width-mobile" style={{ textAlign: 'left' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: '50%', paddingRight: '10px' }}>
                                            <div>First Name:</div>
                                            <input type="text" placeholder="First Name" defaultValue={user.firstName} style={fieldStyles} onBlur={(e) => updateUserProperty('firstName', e.target.value)} />
                                        </div>
                                        <div style={{ width: '50%', paddingLeft: '10px' }}>
                                            <div>Spouse First Name:</div>
                                            <input type="text" placeholder="Spouse First Name" defaultValue={user.spouseFirstName} style={fieldStyles} onBlur={(e) => updateUserProperty('spouseFirstName', e.target.value)} />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: '50%', paddingRight: '10px' }}>
                                            <div>Last Name:</div>
                                            <input type="text" placeholder="Last Name" defaultValue={user.lastName} style={fieldStyles} onBlur={(e) => updateUserProperty('lastName', e.target.value)} />
                                        </div>
                                        <div style={{ width: '50%', paddingLeft: '10px' }}>
                                            <div>Spouse Last Name:</div>
                                            <input type="text" placeholder="Last Name" defaultValue={user.spouseLastName} style={fieldStyles} onBlur={(e) => updateUserProperty('spouseLastName', e.target.value)} />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: '50%', paddingRight: '10px' }}>
                                            <div>Email Address:</div>
                                            <input type="text" placeholder="Email Address" defaultValue={user.email} style={fieldStyles} onBlur={(e) => updateUserProperty('email', e.target.value)} />
                                        </div>
                                        <div style={{ width: '50%', paddingLeft: '10px' }}>
                                            <div>Spouse Email Address:</div>
                                            <input type="text" placeholder="Email Address" defaultValue={user.spouseEmail} style={fieldStyles} onBlur={(e) => updateUserProperty('spouseEmail', e.target.value)} />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: '50%', paddingRight: '10px' }}>
                                            <div style={{ marginTop: '10px' }}>
                                                <div style={{ display: 'inline-block', verticalAlign: 'top', marginRight: '10px' }}>Cell Phone:</div>
                                                <img style={{ cursor: 'pointer' }} src="https://cdn2.iconfinder.com/data/icons/font-awesome/1792/phone-512.png" width="25" height="25" />
                                            </div>
                                            <input type="text" placeholder="Cell Phone" defaultValue={user.cellPhone} style={fieldStyles} onBlur={(e) => updateUserProperty('cellPhone', e.target.value)} />
                                        </div>
                                        <div style={{ width: '50%', paddingLeft: '10px' }}>
                                            <div style={{ marginTop: '10px' }}>
                                                <div style={{ display: 'inline-block', verticalAlign: 'top', marginRight: '15px' }}>Spouse Phone:</div>
                                                <img style={{ cursor: 'pointer' }} src="https://cdn2.iconfinder.com/data/icons/font-awesome/1792/phone-512.png" width="25" height="25" />
                                            </div>
                                            <input type="text" placeholder="Alt Phone" defaultValue={user.homePhone} style={fieldStyles} onBlur={(e) => updateUserProperty('homePhone', e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="forty-five-width full-width-mobile" style={{ textAlign: 'left' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div style={{ width: '50%', paddingRight: '10px' }}>
                                            <div>Company:</div>
                                            <select style={fieldStyles} value={chosenAffiliate} onChange={(e) => setChosenAffiliate(e.target.value)}>
                                                <option value="">Select A Company</option>
                                                {affiliates && affiliates.map((aff) => {
                                                    return <option key={aff.id} value={aff.id}>{aff.name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div style={{ width: '50%', paddingLeft: '10px' }}>
                                            <div>Employee ID:</div>
                                            <input type="text" placeholder="Employee ID" defaultValue={user.authID} style={fieldStyles} onBlur={(e) => updateUserProperty('authID', e.target.value)} />
                                        </div>
                                    </div>
                                    <div>
                                        <div>Start Date:</div>
                                        <DatePicker className="datepicker-input" onChange={(date) => handleStartDate(date)} selected={startDate} />
                                    </div>
                                    <div>
                                        <div>Employee ID:</div>
                                        <input type="text" placeholder="Employee ID" defaultValue={user.authID} style={fieldStyles} onBlur={(e) => updateUserProperty('authID', e.target.value)} />
                                    </div>
                                    <div>
                                        <div>Move Date:</div>
                                        <DatePicker className="datepicker-input" onChange={(date) => handleMoveDate(date)} selected={moveDate} />
                                    </div>
                                    <div>
                                        <div>Consultant Email:</div>
                                        <input type="text" placeholder="Consultant Email" defaultValue={user.consultantEmail} style={fieldStyles} onBlur={(e) => updateUserProperty('consultantEmail', e.target.value)} />
                                    </div>
                                    <div>
                                        <div>Next Call:</div>
                                        <DatePicker className="datepicker-input" showTimeSelect onChange={(date) => handleCallDate(date)} selected={callDate} dateFormat="MM/dd/yyyy h:mm aa" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <div style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '10px', textAlign: 'left' }}>Address Information</div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                <div className="forty-five-width full-width-mobile">
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px', textAlign: 'left' }}>To Address:</div>
                                    <input type="text" placeholder="Street Address" defaultValue={user.newAddress} style={fieldStyles} onBlur={(e) => updateUserProperty('newAddress', e.target.value)} />
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <input type="text" placeholder="City" defaultValue={user.newCity} style={{ ...fieldStyles, width: '50%' }} onBlur={(e) => updateUserProperty('newCity', e.target.value)} />
                                        <input type="text" placeholder="State" defaultValue={user.newState} style={{ ...fieldStyles, width: '15%' }} onBlur={(e) => updateUserProperty('newState', e.target.value)} maxLength="2" />
                                        <input type="text" placeholder="Zip Code" defaultValue={user.newZip} style={{ ...fieldStyles, width: '25%' }} onBlur={(e) => updateUserProperty('newZip', e.target.value)} maxLength="5" />
                                    </div>
                                </div>
                                <div className="forty-five-width full-width-mobile">
                                    <div style={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '10px', textAlign: 'left' }}>From Address:</div>
                                    <input type="text" placeholder="Street Address" defaultValue={user.oldAddress} style={fieldStyles} onBlur={(e) => updateUserProperty('oldAddress', e.target.value)} />
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <input type="text" placeholder="City" defaultValue={user.oldCity} style={{ ...fieldStyles, width: '50%' }} onBlur={(e) => updateUserProperty('oldCity', e.target.value)} />
                                        <input type="text" placeholder="State" defaultValue={user.oldState} style={{ ...fieldStyles, width: '15%' }} onBlur={(e) => updateUserProperty('oldState', e.target.value)} maxLength="2" />
                                        <input type="text" placeholder="Zip Code" defaultValue={user.oldZip} style={{ ...fieldStyles, width: '25%' }} onBlur={(e) => updateUserProperty('oldZip', e.target.value)} maxLength="5" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <button className="clickable-button" onClick={() => submitChanges()}>Add User</button>
                            <button className="clickable-button" style={{ marginLeft: '10px' }} onClick={() => toDashboard()}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </section>
    )
}

export default AddUser