import React, { useState, useEffect } from 'react'
import {
    useLocation,
    useHistory
} from 'react-router-dom'

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

const Apartments = () => {
    let query = useQuery();

    let location = query.get("location");

    const [locationInput, setLocationInput] = useState(location);

    const handleLocationInputChange = (location) => {
        setLocationInput(location);
    }

    const history = useHistory();

    const [searching, setSearching] = useState(false);
    const handleSearch = () => {
        history.push('apartments?location=' + locationInput);
        location = query.get('location');
        searchApartments();
    }

    const searchApartments = () => {
        setSearching(true);
        fetch('../api/Apartments/getbylocation/' + locationInput)
            .then((res) => {
                if (res.ok) return res.json();
                alert("Could not find apartments for this location.");
                setSearching(false);
            })
            .then((json) => {
                console.log(json);
                setApartments(json);
                setSearching(false);
            });
    }

    useEffect(() => {
        if (!searchedApartments) {
            setSearchedApartments(true);
            searchApartments();
        }
    });

    const [apartments, setApartments] = useState([]);
    const [searchedApartments, setSearchedApartments] = useState(false);

    const numPages = (apartments && apartments.length) ? Math.ceil(apartments.length / 25) : 0;
    const [page, setPage] = useState(1);

    const prevPage = () => {
        if (page > 1)
            setPage(page - 1);
    }

    const nextPage = () => {
        if (page < numPages)
            setPage(page + 1);
    }

    const toDetail = (id) => { }

    const call = (number) => {
        window.location.href = 'tel:' + number;
    }

    const updateFilter = (filter, value) => {
        const result = {};
        result[filter] = value;
        setFilters({ ...filters, ...result });
    }

    const [filters, setFilters] = useState({ maxPrice: 5000, minPrice: 0, beds: 0, baths: 0, dogs: false, cats: false });

    var filteredApartments = (apartments && apartments.length) ? apartments.filter((apartment) => {
        return apartment.minimumRent <= filters.maxPrice
            && apartment.maximumRent >= filters.minPrice
            && (filters.baths == undefined || apartment.maximumBathrooms >= filters.baths)
            && (filters.beds == undefined || apartment.maximumBedrooms >= filters.beds)
            && (!filters.dogs || apartment.maximumDogs > 0)
            && (!filters.cats || apartment.maximumCats > 0);
    }) : [];

    const displayApartments = (filteredApartments && filteredApartments.length) ? filteredApartments.slice((page - 1) * 24, Math.min(page * 24, filteredApartments.length)) : [];


    return (
        <div style={{ width: '100%', maxWidth: '900px', margin: 'auto', marginTop: '20px', backgroundColor: '#efefef', border: '1px solid #ccc', borderRadius: '5px', textAlign: 'center', position: 'relative' }}>
            <div style={{ padding: '20px' }}>
                <div style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>Apartments</div>
                <div style={{ textAlign: 'center' }}>
                    <label for="location-input">Location:</label>
                    <input value={locationInput} onChange={(e) => handleLocationInputChange(e.target.value)} type="text" id="location-input" />

                    <button
                        onClick={handleSearch}
                        style={{ marginLeft: '10px', background: 'white', borderRadius: '5px', border: '1px solid #ccc', width: '100px', height: '30px', cursor: 'pointer', display: 'inline-block', fontSize: '12px', lineHeight: '30px' }}
                    >
                        Search
                    </button>
                    <div style={{ display: 'flex', justifyContent: 'space-around', textAlign: 'left', marginTop: '20px', marginBottom: '20px' }}>
                        <div>
                            <div style={{ fontWeight: 'bold', fontSize: '18px' }}>Price</div>
                            <div>
                                $<input value={filters.minPrice} onChange={(e) => updateFilter('minPrice', e.target.value)} type="text" style={{ width: '40%' }} />
                                &nbsp;-&nbsp;
                                $<input value={filters.maxPrice} onChange={(e) => updateFilter('maxPrice', e.target.value)} type="text" style={{ width: '40%' }} />
                            </div>
                        </div>
                        <div>
                            <div style={{ fontWeight: 'bold', fontSize: '18px' }}>Beds</div>
                            <div>
                                <input value={filters.beds} onChange={(e) => updateFilter('beds', e.target.value)} type="text" style={{ width: '50%' }} /> and up
                            </div>
                        </div>
                        <div>
                            <div style={{ fontWeight: 'bold', fontSize: '18px' }}>Baths</div>
                            <div>
                                <input value={filters.baths} onChange={(e) => updateFilter('beds', e.target.value)} type="text" style={{ width: '50%' }} /> and up
                            </div>
                        </div>
                        <div style={{ width: '200px' }}>
                            <div style={{ fontWeight: 'bold', fontSize: '18px' }}>Pets</div>
                            <div>
                                <button
                                    onClick={() => updateFilter('dogs', !filters.dogs)}
                                    style={{ background: (filters.dogs) ? '#efefef' : 'white', borderRadius: '5px', border: '1px solid #ccc', width: '60px', height: '30px', cursor: 'pointer', display: 'inline-block', fontSize: '12px', lineHeight: '30px' }}
                                >
                                    Dogs
                                </button>
                                <button
                                    onClick={() => updateFilter('cats', !filters.cats)}
                                    style={{ marginLeft: '10px', background: (filters.cats) ? '#efefef' : 'white', borderRadius: '5px', border: '1px solid #ccc', width: '60px', height: '30px', cursor: 'pointer', display: 'inline-block', fontSize: '12px', lineHeight: '30px' }}
                                >
                                    Cats
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {(!displayApartments || !displayApartments.length) && (
                    <div>No results to display.</div>
                )}
                {displayApartments && displayApartments.length && (
                    <>
                        <div style={{ position: 'relative', color: 'black', marginTop: '20px' }}>
                            <div onClick={prevPage}
                                style={{ position: 'absolute', left: '2.5%', cursor: 'pointer' }}>{ page == 1 ? '' : 'Prev Page' }</div>
                            <div onClick={nextPage}
                                style={{ position: 'absolute', right: '2.5%', cursor: 'pointer' }}>{page == numPages ? '' : 'Next Page'}</div>
                            <div style={{ textAlign:'center' }}>
                                    Page
                                        <span>{page}</span> of <span>{numPages}</span>
                                        (<span>{displayApartments && displayApartments.length ? displayApartments.length : 0}</span> results)
                                    </div>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            {displayApartments.map((x) => {
                                return (
                                    <div key={x.propertyID} style={{width: '95%', maxWidth: '380px', display: 'inline-block', backgroundColor:'white', padding:'10px', margin:'10px', textAlign:'left'}}>
                                        <div onClick={() => toDetail(x.propertyID)} style={{ textAlign: 'center' }}><img src={x.thumbnailURL} style={{width: 'auto', maxHeight: '200px', cursor:'pointer'}} /></div>
                                        <div style={{padding: '10px', paddingBottom: '0'}}>
                                            <div>
                                                <div style={{ color: 'green', fontSize: '16px' }}>{x.rent}</div>
                                            </div>
                                            <div style={{ fontSize: '14px', fontWeight: 'bold', color: 'black' }}>{x.propertyName}</div>
                                            <div style={{ fontSize:'12px', color: '#999' }}>{x.city}</div>
                                            <div>
                                                <div><span>{x.beds}</span> Beds</div>
                                                <div><span>{x.baths}</span> Baths</div>
                                            </div>
                                            <div style={{width: '100%', marginTop:'10px', textAlign: 'center'}}>
                                                <div
                                                    style={{ background: 'none', borderRadius: '5px', border: '1px solid #ccc', width: '150px', height: '30px', cursor: 'pointer', display: 'inline-block', fontSize: '12px', lineHeight: '30px' }}
                                                    onClick={() => call(x.propertyPhoneNumber.replace(/\D+/g, ''))}>{x.propertyPhoneNumber.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div style={{ position: 'relative', color: 'black', marginBottom: '20px' }}>
                            <div onClick={prevPage}
                                style={{ position: 'absolute', left: '2.5%', cursor: 'pointer' }}>{page == 1 ? '' : 'Prev Page'}</div>
                            <div onClick={nextPage}
                                style={{ position: 'absolute', right: '2.5%', cursor: 'pointer' }}>{page == numPages ? '' : 'Next Page'}</div>
                            <div style={{ textAlign: 'center' }}>
                                    Page
                                        <span>{page}</span> of <span>{numPages}</span>
                                        (<span>{displayApartments && displayApartments.length ? displayApartments.length : 0}</span> results)
                                    </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default Apartments;

/*<div
    style={{ background: 'none', borderRadius: '5px', border: '1px solid #ccc', width: '150px', height: '30px', cursor: 'pointer', display: 'inline-block', fontSize: '12px', lineHeight: '30px' }}
    data-bind="click: $parent.toDetail.bind($data, propertyID())">Check Availability</div>*/