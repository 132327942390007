import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

const Affiliate = () => {
    const history = useHistory()
    const [users, setUsers] = useState([])
    const [usersFetched, setUsersFetched] = useState(false)
    const [filter, setFilter] = useState({
        search: '',
        sortParam: 'modifiedDate',
        sortDescending: true,
        type: 'all'
    })
    const [page, setPage] = useState(1)

    const { id } = useParams()

    const [affiliateName, setAffiliateName] = useState()
    const [affiliateNameFetched, setAffiliateNameFetched] = useState(false)

    useEffect(() => {
        (async () => {
            const validLogin = Boolean(sessionStorage.getItem('loggedIn'))

            if (!validLogin)
                history.push('/login')
            else {
                if (!usersFetched) {
                    const userFetch = await fetch('../api/Users/byaffiliate/' + id);
                    const users = await userFetch.json();
                    setUsers(users)
                    setUsersFetched(true)
                }

                if (!affiliateNameFetched) {
                    fetch('../api/Affiliates/' + id)
                        .then((res) => {
                            if (res.ok) return res.json()
                            alert('Error fetching affiliate.')
                        })
                        .then((affiliate) => {
                            setAffiliateName(affiliate.name)
                            setAffiliateNameFetched(true)
                        })
                }
            }
        })();

        return () => {}
    })

    const toUpload = () => {
        history.push('/upload')
    }

    const toUser = (id) => {
        history.push('/user/' + id)
    }

    const toAddUser = () => {
        history.push('/user/add')
    }

    const toAffiliates = () => {
        history.push('/affiliates')
    }

    const sendTestEmail = () => {
        fetch('api/Users/testemail')
            .then((res) => {
                if (res.ok) console.log(res.json())
                else alert('Issue sending email')
            })
    }

    const setTab = (type) => {
        setFilter({ ...filter, type: type })
        setPage(1)
    }

    const handleSearchChange = (value) => {
        setFilter({ ...filter, search: value, type: 'all' })
        setPage(1)
    }

    const handleHeaderClick = (param) => {
        if (filter.sortParam === param)
            setFilter({ ...filter, sortDescending: !filter.sortDescending })
        else
            setFilter({ ...filter, sortParam: param, sortDescending: false })
    }

    const handlePageClick = (type, gotoPage) => {
        if (type === 'goto') {
            setPage(gotoPage)
        } else if (type === 'next') {
            setPage(page + 1)
        } else if (type === 'prev') {
            setPage(page - 1)
        }
    }


    /*|| x.notes.filter(note => note.text.toLowerCase().includes(filter.search.toLowerCase())))*/

    let filtered = (filter.search && filter.search.length > 0) ?
        users.filter(x => x.firstName?.toLowerCase().includes(filter.search)
            || x.lastName?.toLowerCase().includes(filter.search)
            || x.email?.toLowerCase().includes(filter.search)
            || x.cellPhone?.replace(/\D/g, '').includes(filter.search)
            || x.subState?.toLowerCase().includes(filter.search)
            || x.oldCity?.toLowerCase().includes(filter.search.toLowerCase())
            || x.newCity?.toLowerCase().includes(filter.search.toLowerCase())
            || x.oldState?.toLowerCase().includes(filter.search.toLowerCase())
            || x.newState?.toLowerCase().includes(filter.search.toLowerCase())
            || x.notes?.map(note => note.toLowerCase()).some(str => str.includes(filter.search.toLowerCase())))
        : users

    filtered = filtered.sort((a, b) => {
        const aIsString = isNaN(new Date(a[filter.sortParam]).getTime())
        const bIsString = isNaN(new Date(b[filter.sortParam]).getTime())

        if (aIsString) {
            if (bIsString) {
                if (a[filter.sortParam] > b[filter.sortParam])
                    return (filter.sortDescending) ? -1 : 1
                else if (a[filter.sortParam] < b[filter.sortParam])
                    return (filter.sortDescending) ? 1 : -1
                else
                    return 0
            } else {
                return -1
            }

        } else {
            if (bIsString) {
                return 1
            } else {
                const aDate = new Date(a[filter.sortParam])
                const bDate = new Date(b[filter.sortParam])

                if (aDate > bDate)
                    return (filter.sortDescending) ? -1 : 1
                else if (aDate < bDate)
                    return (filter.sortDescending) ? 1 : -1
                else
                    return 0
            }
        }
    })

    const active = filtered.filter(x => x.state === 'active')
    const pending = filtered.filter(x => x.state === 'pending')
    const completed = filtered.filter(x => x.state === 'completed')
    const closed = filtered.filter(x => x.state === 'closed')

    const arrow = (filter.sortDescending) ? <span>&darr;</span> : <span>&uarr;</span>

    const noElements = (filtered && filtered.length === 0)
        || (filter.type === 'active' && active.length === 0)
        || (filter.type === 'pending' && pending.length === 0)
        || (filter.type === 'completed' && completed.length === 0)
        || (filter.type === 'closed' && closed.length === 0)


    const tabStyle = {
        height: '24px',
        lineHeight: '24px',
        cursor: 'pointer',
        fontSize: '16px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        margin: '5px'
    }

    return (
        <div style={{ width: '100%', maxWidth: '900px', margin: 'auto', marginTop: '20px', backgroundColor: '#efefef', border: '1px solid #ccc', borderRadius: '5px', textAlign: 'center', position: 'relative' }}>
            <div style={{ padding: '20px' }}>
                <div style={{ fontSize: '24px', position: 'absolute', top: '10px', right: '20px', cursor: 'pointer' }} onClick={toAffiliates}>X</div>
                <div style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>{affiliateName}</div>
                <div style={{ height: '30px', textAlign: 'left', borderRadius: '5px' }}>
                    <input className="search" type="text" placeholder="Search..." onChange={(e) => handleSearchChange(e.target.value.toLowerCase())} />
                    <button
                        className="clickable-button"
                        onClick={() => toUpload()}
                        style={{ float: 'right', backgroundColor: 'white', cursor: 'pointer', border: '1px solid #aaa' }}
                    >
                        Upload Sheet
                        </button>
                    <button
                        className="clickable-button"
                        onClick={() => toAddUser()}
                        style={{ float: 'right', backgroundColor: 'white', cursor: 'pointer', border: '1px solid #aaa' }}
                    >
                        Add New User
                        </button>
                </div>
                <div style={{ margin: '20px 0', textAlign: 'left' }}>
                    <button
                        onClick={() => setTab('all')}
                        style={{ ...tabStyle, backgroundColor: (filter.type === 'all') ? 'lightblue' : 'white' }}
                    >
                        All Users ({filtered.length})
                        </button>
                    <button
                        onClick={() => setTab('active')}
                        style={{ ...tabStyle, backgroundColor: (filter.type === 'active') ? 'lightblue' : 'white' }}
                    >
                        Active Users ({active.length})
                        </button>
                    <button
                        onClick={() => setTab('pending')}
                        style={{ ...tabStyle, backgroundColor: (filter.type === 'pending') ? 'lightblue' : 'white' }}
                    >
                        Pending Users ({pending.length})
                        </button>
                    <button
                        onClick={() => setTab('completed')}
                        style={{ ...tabStyle, backgroundColor: (filter.type === 'completed') ? 'lightblue' : 'white' }}
                    >
                        Completed Users ({completed.length})
                        </button>
                    <button
                        onClick={() => setTab('closed')}
                        style={{ ...tabStyle, backgroundColor: (filter.type === 'closed') ? 'lightblue' : 'white' }}
                    >
                        Closed Users ({closed.length})
                        </button>
                </div>
                <hr style={{ width: '80%', margin: '10px auto', color: '#aaa' }} />
                {noElements && (
                    <div style={{ textAlign: 'center', fontSize: '14px' }}>There are no users to display.</div>
                )}
                {filter.type === 'all' && !noElements && <UserList list={filtered} toUser={toUser} handleHeaderClick={handleHeaderClick} arrow={arrow} sortParam={filter.sortParam} page={page} handlePageClick={handlePageClick} />}
                {filter.type === 'active' && !noElements && <UserList list={active} toUser={toUser} handleHeaderClick={handleHeaderClick} arrow={arrow} sortParam={filter.sortParam} page={page} handlePageClick={handlePageClick} />}
                {filter.type === 'pending' && !noElements && <UserList list={pending} toUser={toUser} handleHeaderClick={handleHeaderClick} arrow={arrow} sortParam={filter.sortParam} page={page} handlePageClick={handlePageClick} />}
                {filter.type === 'completed' && !noElements && <UserList list={completed} toUser={toUser} handleHeaderClick={handleHeaderClick} arrow={arrow} sortParam={filter.sortParam} page={page} handlePageClick={handlePageClick} />}
                {filter.type === 'closed' && !noElements && <UserList list={closed} toUser={toUser} handleHeaderClick={handleHeaderClick} arrow={arrow} sortParam={filter.sortParam} page={page} handlePageClick={handlePageClick} />}
            </div>
        </div>
    )
}

const UserList = ({ list, toUser, handleHeaderClick, arrow, sortParam, page, handlePageClick }) => {

    const numPages = Math.ceil(list.length / 25)

    list = list.slice((page - 1) * 25, Math.min(page * 25, list.length))

    const handleCall = (number) => {
        window.location.href = 'tel:' + number;
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', minHeight: '32px', lineHeight: '32px', fontSize: '14px', fontWeight: 'bold', backgroundColor: '#ccc', textAlign: 'left', cursor: 'pointer' }}>
                <div
                    style={{ flex: 1.5, overflowWrap: 'break-word' }}
                    onClick={() => handleHeaderClick('lastName')}
                >
                    Name {sortParam === 'lastName' && arrow}
                </div>
                <div
                    className="hide-mobile"
                    style={{ flex: 2, overflowWrap: 'break-word' }}
                    onClick={() => handleHeaderClick('email')}
                >
                    Email  {sortParam === 'email' && arrow}
                </div>
                <div
                    style={{ flex: 1, overflowWrap: 'break-word' }}
                    onClick={() => handleHeaderClick('cellPhone')}
                >
                    Cell Phone  {sortParam === 'cellPhone' && arrow}
                </div>
                <div
                    className="hide-mobile"
                    style={{ flex: 1, overflowWrap: 'break-word' }}
                    onClick={() => handleHeaderClick('moveDate')}
                >
                    Move Date  {sortParam === 'moveDate' && arrow}
                </div>
                <div
                    className="hide-mobile"
                    style={{ flex: 1, overflowWrap: 'break-word' }}
                    onClick={() => handleHeaderClick('modifiedDate')}
                >
                    Last Modified  {sortParam === 'modifiedDate' && arrow}
                </div>
                <div
                    className="hide-mobile"
                    style={{ flex: 1, overflowWrap: 'break-word' }}
                    onClick={() => handleHeaderClick('subState')}
                >
                    Sub Status  {sortParam === 'subState' && arrow}
                </div>
            </div>
            {
                list.map(x => {
                    return (
                        <div
                            key={x.id}
                            style={{ display: 'flex', justifyContent: 'space-between', width: '100%', minHeight: '32px', lineHeight: '32px', fontSize: '14px', textAlign: 'left', cursor: 'pointer' }}
                        >
                            <div style={{ flex: 1.5, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', padding: '0 5px' }}
                                onClick={() => toUser(x.id)}>{x.firstName + ' ' + x.lastName}</div>
                            <div className="hide-mobile" style={{ flex: 2, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', padding: '0 5px' }}
                                onClick={() => toUser(x.id)}>{x.email}</div>
                            <div style={{ flex: 1, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', padding: '0 5px' }}
                                onClick={() => handleCall(x.cellPhone?.replace(/\D+/g, ''))}>{x.cellPhone?.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}</div>
                            <div className="hide-mobile" style={{ flex: 1, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', padding: '0 5px' }}
                                onClick={() => toUser(x.id)}>{x.moveDate ? new Date(x.moveDate).toLocaleDateString() : 'None'}</div>
                            <div className="hide-mobile" style={{ flex: 1, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', padding: '0 5px' }}
                                onClick={() => toUser(x.id)}>{new Date(x.modifiedDate).toLocaleDateString()}</div>
                            <div className="hide-mobile" style={{ flex: 1, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', padding: '0 5px' }}
                                onClick={() => toUser(x.id)}>{x.subState ? x.subState : 'None'}</div>
                        </div>
                    )
                })
            }
            <div className="disable-select" style={{ textAlign: 'center', cursor: 'pointer', fontWeight: 'bold', marginTop: '10px', marginBottom: '20px', overflowWrap: 'break-word' }}>
                {page > 1 && <span onClick={() => handlePageClick('prev')} style={{ marginRight: '5px' }}>&lt;</span>}
                {
                    [...Array(numPages)].map((e, i) => {
                        return <span key={i} onClick={() => handlePageClick('goto', i + 1)} style={{ color: (page === i + 1) ? 'royalblue' : '#333', margin: '0 5px' }}>{i + 1}</span>
                    })
                }
                {page < numPages && <span onClick={() => handlePageClick('next')} style={{ marginLeft: '5px' }}>&gt;</span>}
            </div>
        </div>
    )
}

export default Affiliate